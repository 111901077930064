import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchWrapper, getUsername } from "./Helpers";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HaltComponent from "./HaltingComponent";
import EmotionComponent from "./EmotionComponent";
import TextParsingComponent from "./TextParsingComponent";
import TurtleImage from "./assets/turtle.jpg";
import ResultComponent from "./ResultComponent";
import ReCAPTCHA from "react-google-recaptcha";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import HowToPlay from "./HowToPlay";
import WingdingsComponent from "./WingdingsComponent";
import IllusionComponent from "./IllusionComponent";
import WinComponent from "./WinComponent";
import MinesweeperComponent from "./MinesweeperComponent";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import TextComponent from "./TextComponent";
import WordLengthComponent from "./WordLengthComponent";
import SentienceCheckComponent from "./SentienceCheckComponent";
import DressImage from "./assets/the_dress.jpeg";
import { Grid } from "@mui/material";

function ReactionComponent({ submitInner }) {
  let [startTime, setStartTime] = useState(null);

  function submit() {
    let timeElapsed = new Date() - startTime;
    if (timeElapsed < 300) {
      submitInner(false, { error: "Inhumanly fast!" });
    } else if (timeElapsed > 2000) {
      submitInner(false, { error: "Inhumanly slow!" });
    } else {
      submitInner(true, { message: "Appropriately medium reaction time!" });
    }
  }

  useEffect(() => {
    let to = setTimeout(
      () => setStartTime(new Date()),
      1000 + Math.random() * 5000,
    );

    return () => clearTimeout(to);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        Press the button as soon as it appears
      </Typography>
      {startTime && (
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          style={{ marginTop: 20 }}
        >
          Submit
        </Button>
      )}
    </div>
  );
}

function BunnyComponent({ submitInner }) {
  let now = useRef(new Date());

  function submit() {
    let timeElapsed = new Date() - now.current;
    submitInner(timeElapsed > 7000, {
      message: "It had to be done",
      error: "It wasn't even a tough choice for you...that's messed up",
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        In order to progress, you must kick this cute bunny
      </Typography>
      <img
        src={"../assets/bunny.png"}
        style={{ width: "75%", height: "auto", margin: "10px" }}
        alt=""
      />
      <Button variant="contained" color="primary" onClick={submit}>
        Punt him
      </Button>
    </div>
  );
}

function DressComponent({ submitInner }) {  
    function submit(answer) {
      const oppositeAnswer = answer === 'white and gold' ? 'blue and black' : 'white and gold';

      submitInner(answer !== '#8797c8 and #675634', {
        message: `No, it's actually ${oppositeAnswer}... but that's a very human mistake to make.`,
        error: "That's a very... precise answer 🤔",
      });
    }
  
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
                <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
                    What color is this dress?
                </Typography>
            </Grid>
            <Grid item>
                <img
                src={DressImage}
                style={{ width: "80%", height: "auto", margin: "10px" }}
                alt=""
                />
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => submit('white and gold')}>
                            White and Gold
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => submit('blue and black')}>
                            Blue and Black
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => submit('#8797c8 and #675634')}>
                            #8797c8 and #675634
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
  }  

function WordSearchComponent({ level, submitInner, squareDimensions }) {
  let grid = level?.data?.grid;
  let [selected, setSelected] = useState([]);

  function toggleSelected(row, col) {
    if (selected.includes(row * 6 + col)) {
      setSelected(selected.filter((item) => item !== row * 6 + col));
    } else {
      setSelected([...selected, row * 6 + col]);
    }
  }

  function submit() {
    let word = "";
    let sortedSelected = selected.sort((a, b) => a - b);
    for (let i = 0; i < sortedSelected.length; i++) {
      let row = Math.floor(sortedSelected[i] / 6);
      let col = sortedSelected[i] % 6;
      word += grid[row][col];
    }
    submitInner(word, level.data);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(28px, 4.5vw, 4.5vh)" }}>{level?.data?.question}</Typography>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}>
        The answer is a straight line of 6 letters somewhere in this word
        search, backwards or forwards
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(6, ${squareDimensions}px)`,
          gridTemplateRows: `repeat(6, ${squareDimensions}px)`,
          gap: "1px",
          border: "1px solid black",
          margin: "20px",
        }}
      >
        {grid.map((row, rowIndex) =>
          row.map((letter, colIndex) => (
            <div
              key={rowIndex * 6 + colIndex}
              style={{
                backgroundColor: "white",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="no-hover"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: 0,
                  minWidth: 0,
                  boxSizing: "border-box",
                }}
                onClick={() => toggleSelected(rowIndex, colIndex)}
              >
                <Typography
                  style={{
                    fontSize: "min(24px, 3.5vw, 3.5vh)",
                    color: selected.includes(rowIndex * 6 + colIndex)
                      ? "red"
                      : "black",
                  }}
                >
                  {letter}
                </Typography>
              </Button>
            </div>
          )),
        )}
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={submit}
        disabled={selected.length !== 6}
      >
        {selected.length === 6
          ? "Submit"
          : "The correct answer is always 6 letters"}
      </Button>
    </div>
  );
}

function LoopComponent({ submitInner }) {
  function submit() {
    submitInner(true, { message: "You're either lying or bad at following instructions...very human" });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>Please do the following steps</Typography>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}>
        1. Set variable <b>x</b> to 3<br />
        2. Press button <b>x</b>
        <br />
        3. Set variable <b>x</b> to 4 minus <b>x</b> <br />
        4. If <b>x</b> is even, press button two. Else, go to step 2.
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <Button variant="contained" color="primary">
          Button One
        </Button>
        <Button variant="contained" color="primary" onClick={submit}>
          Button Two
        </Button>
        <Button variant="contained" color="primary">
          Button Three
        </Button>
      </div>
    </div>
  );
}

function FlappyBirdComponent({ submitInner, fast }) {
  const [birdPosition, setBirdPosition] = useState(250);
  const [xIndex, setXIndex] = useState(0);
  const [gameOver, setGameOver] = useState(null);
  const [started, setStarted] = useState(false);
  let [lastFlap, setLastFlap] = useState(5);
  const gameHeight = 500;
  const obstacleWidth = 60;
  const obstacleGap = 200;
  const birdSize = 30;
  const obstacles = useRef(generateObstacles());

  const smallScreen = window.innerWidth < 600 || window.innerHeight < 600;

  function generateObstacles() {
    let obs = [];
    for (let i = 2; i < 10; i++) {
      let height = Math.random() * (gameHeight - obstacleGap);
      obs.push({
        x: i * 200,
        topHeight: height,
        bottomHeight: gameHeight - height - obstacleGap,
      });
    }
    return obs;
  }

  let flap = useCallback(() => {
    if (!gameOver) {
      if (!started) {
        setStarted(true);
      }
      setBirdPosition((birdPosition) => Math.max(birdPosition + 15, 0));
      setLastFlap(0);
    }
  }, [gameOver, started]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === "Space") {
        e.preventDefault();
        flap();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [flap]);

  let checkCollision = useCallback(() => {
    if (xIndex >= 1900) {
      submitInner(true);
      setGameOver(true);
      return true;
    }
    if (birdPosition >= gameHeight - birdSize || birdPosition < 0) {
      submitInner(false);
      setGameOver(true);
      return true;
    }
    let obs = null;
    for (let i = 0; i < obstacles.current.length; i++) {
      obs = obstacles.current[i];
      if (xIndex + birdSize >= obs.x && xIndex < obs.x + obstacleWidth) {
        if (
          birdPosition + birdSize > gameHeight - obs.topHeight ||
          birdPosition < obs.bottomHeight
        ) {
          submitInner(false);
          setGameOver(true);
          return true;
        }
      }
    }
  }, [birdPosition, gameHeight, submitInner, xIndex]);

  useEffect(() => {
    let speedMultiplier = fast ? 2.5 : 1;
    const interval = setInterval(() => {
      if (!gameOver && started) {
        if (!checkCollision()) {
          let gravity = (lastFlap - 10) / 12;
          setBirdPosition((birdPosition) =>
            Math.min(
              birdPosition - gravity * speedMultiplier,
              gameHeight - birdSize,
            ),
          );
          setLastFlap((lastFlap) => lastFlap + 1);
          setXIndex((xIndex) => xIndex + 2 * speedMultiplier);
        }
      }
    }, 20 / speedMultiplier);

    return () => clearInterval(interval);
  }, [checkCollision, birdPosition, gameOver, lastFlap, started, fast]);

  let ml = 20;

  return (
    <div
      style={{
        height: `${gameHeight}px`,
        width: "100%",
        position: "relative",
        overflow: "hidden",
        backgroundColor: fast ? null : "skyblue",
        animation: fast ? "rainbow 5s infinite" : null,
      }}
      onClick={flap}
    >
      <div style={{ marginLeft: `${ml}px` }}>
        <div
          style={{
            position: "absolute",
            bottom: `${birdPosition}px`,
            width: `${birdSize}px`,
            height: `${birdSize}px`,
            backgroundColor: "yellow",
            borderRadius: "50%",
          }}
        ></div>
        {obstacles.current.map((obstacle, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              left: `${obstacle.x - xIndex + ml}px`,
              width: `${obstacleWidth}px`,
            }}
          >
            <div
              style={{
                height: `${obstacle.topHeight}px`,
                backgroundColor: "green",
              }}
            ></div>
            <div
              style={{
                height: `${obstacle.bottomHeight}px`,
                backgroundColor: "green",
                marginTop: `${obstacleGap}px`,
              }}
            ></div>
          </div>
        ))}
        {gameOver && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              fontSize: "min(24px, 3.5vw, 3.5vh)",
            }}
          >
            Game Over!
          </div>
        )}
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ position: "absolute", bottom: "10px", right: "10px" }}
        onClick={flap}
      >
        {`Flap (or ${smallScreen ? "tap the screen" : "press spacebar"})`}
      </Button>
    </div>
  );
}

function AbundanceComponent({ submitInner }) {
  let options = ["fairy cake", "ballet bun", "abundance", "cruel!"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>What is this?</Typography>
      <img
        src={"../assets/abundance.png"}
        style={{ width: "50%", height: "auto", aspectRatio: "1 / 1" }}
        alt=""
      />
      <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
        {options.map((option, index) => (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitInner(option)}
              key={index}
            >
              <Typography key={index}>{option}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function AitaComponent({ level, submitInner }) {
  let isAsshole = level.data.is_asshole;

  function submit(answer) {
    submitInner(answer === isAsshole, {
      error: "Unfortunately, you are the asshole",
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        This is a real question from r/AmITheAsshole, judged by well-adjusted,
        normal, socially competent individuals.
      </Typography>
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)", margin: "min(20px, 3vw, 3vh)" }}>
        {level?.data?.title}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => submit(true)}
        >
          They are the asshole
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => submit(false)}
        >
          Not the asshole
        </Button>
      </div>
    </div>
  );
}

const CROSSWORD_BOUNDARY_CHANCE = 0.5;

function checkContiguity(grid) {
  const directions = [
    [1, 0],
    [0, 1],
    [-1, 0],
    [0, -1],
  ];
  const visited = new Set();
  const queue = [];
  let firstEmptyFound = false;

  // Find the first empty cell and start BFS from there
  for (let y = 0; y < grid.length && !firstEmptyFound; y++) {
    for (let x = 0; x < grid[y].length && !firstEmptyFound; x++) {
      if (grid[y][x] === "") {
        queue.push([x, y]);
        visited.add(`${x},${y}`);
        firstEmptyFound = true;
      }
    }
  }

  // BFS to visit all contiguous empty cells
  while (queue.length > 0) {
    const [cx, cy] = queue.shift();
    for (const [dx, dy] of directions) {
      const nx = cx + dx;
      const ny = cy + dy;
      if (
        nx >= 0 &&
        nx < grid[0].length &&
        ny >= 0 &&
        ny < grid.length &&
        grid[ny][nx] === "" &&
        !visited.has(`${nx},${ny}`)
      ) {
        queue.push([nx, ny]);
        visited.add(`${nx},${ny}`);
      }
    }
  }

  // Check if all empty cells were visited
  for (let y = 0; y < grid.length; y++) {
    for (let x = 0; x < grid[y].length; x++) {
      if (grid[y][x] === "" && !visited.has(`${x},${y}`)) {
        return false; // Found an unvisited empty cell
      }
    }
  }

  return true; // All empty cells are contiguous
}

const GRID_SIZE = 8;

const generateGrid = () => {
  let grid = Array.from({ length: GRID_SIZE }, () =>
    Array.from({ length: GRID_SIZE }, () =>
      Math.random() < CROSSWORD_BOUNDARY_CHANCE ? "." : "",
    ),
  );
  while (!checkContiguity(grid)) {
    grid = Array.from({ length: GRID_SIZE }, () =>
      Array.from({ length: GRID_SIZE }, () =>
        Math.random() < CROSSWORD_BOUNDARY_CHANCE ? "." : "",
      ),
    );
  }
  return grid;
};

function CrosswordComponent({ submitInner, squareDimensions }) {
  const [position, setPosition] = useState({ x: -1, y: 0 });
  const [grid, setGrid] = useState(generateGrid());
  const [submitted, setSubmitted] = useState(false);
  const [dir, setDir] = useState("across");
  const [scrabbleWords, setScrabbleWords] = useState(null);
  const [confirming, setConfirming] = useState(false);

  useEffect(() => {
    import("./scrabbleWords.js").then((module) => {
      setScrabbleWords(module.default());
    });
  }, []);

  function newGrid() {
    setConfirming(false);
    setGrid(generateGrid());
    setPosition({ x: -1, y: 0 });
  }

  useEffect(() => {
    if (position.x === -1 || position.y === -1) {
      return; // Early return if the position is not yet initialized
    }

    const x = position.x;
    const y = position.y;

    // Check boundaries around the current position
    const leftBoundary = x === 0 || grid[y][x - 1] === ".";
    const rightBoundary = x === grid[0].length - 1 || grid[y][x + 1] === ".";
    const topBoundary = y === 0 || grid[y - 1][x] === ".";
    const bottomBoundary = y === grid.length - 1 || grid[y + 1][x] === ".";

    if (leftBoundary && rightBoundary) {
      setDir("down");
    } else if (topBoundary && bottomBoundary) {
      setDir("across");
    }
  }, [position, grid]);

  const shift = useCallback(
    (x, y) => {
      if (x !== 0 && dir === "down") {
        setDir("across");
        return;
      } else if (y !== 0 && dir === "across") {
        setDir("down");
        return;
      }
      let newPos = {
        x: ((position.x || GRID_SIZE) + x) % GRID_SIZE,
        y:
          Math.min(GRID_SIZE - 1, Math.max(0, position.y + y)) +
          (position.x + x < 0 ? -1 : position.x + x > GRID_SIZE - 1 ? 1 : 0),
      };
      if (newPos.x < 0 || newPos.x > GRID_SIZE - 1 || newPos.y < 0 || newPos.y > GRID_SIZE - 1) {
        return;
      }
      while (grid[newPos.y][newPos.x] === ".") {
        newPos = {
          x: ((newPos.x || GRID_SIZE) + x) % GRID_SIZE,
          y: newPos.y + y + (newPos.x + x < 0 ? -1 : newPos.x + x > GRID_SIZE - 1 ? 1 : 0),
        };
        if (newPos.x < 0 || newPos.x > GRID_SIZE - 1 || newPos.y < 0 || newPos.y > GRID_SIZE - 1) {
          return;
        }
      }
      setPosition(newPos);
    },
    [position, grid, dir],
  );

  const nextPosition = useCallback(
    (forwards = true) => {
      if (dir === "across") {
        shift(forwards ? 1 : -1, 0);
      } else {
        shift(0, forwards ? 1 : -1);
      }
    },
    [dir, shift],
  );

  useEffect(() => {
    if (position.x === -1 && position.y === 0) {
      nextPosition();
    }
  }, [position, nextPosition]);

  const setLetter = useCallback(
    (letter, forwards = true) => {
      if (position.x === undefined || position.y === undefined) {
        return;
      } else if (grid[position.y][position.x] === ".") {
        return;
      }
      setGrid((prevGrid) => {
        let newGrid = JSON.parse(JSON.stringify(prevGrid));
        newGrid[position.y][position.x] = letter;
        nextPosition(forwards);
        return newGrid;
      });
    },
    [grid, position, nextPosition],
  );

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key.length === 1 && e.key.match(/[a-zA-Z]/)) {
        e.preventDefault();
        setLetter(e.key.toUpperCase());
      } else if (e.key === "Backspace") {
        e.preventDefault();
        setLetter("", false);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        shift(0, -1);
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        shift(0, 1);
      } else if (e.key === "ArrowLeft") {
        e.preventDefault();
        shift(-1, 0);
      } else if (e.key === "ArrowRight") {
        e.preventDefault();
        shift(1, 0);
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [setLetter, shift]);

  const isInvalidGrid = Array.from({ length: GRID_SIZE * GRID_SIZE }).map((_, index) => {
    let x = index % GRID_SIZE;
    let y = Math.floor(index / GRID_SIZE);

    let cantBeInvalid = false;
    if (grid[y][x] === ".") {
      cantBeInvalid = true;
    }

    // Extract horizontal word
    let horizontalWord = "";
    let cantBeInvalidHorizontal = false;
    let startX = x;
    while (startX > 0 && grid[y][startX - 1] !== ".") {
      startX -= 1;
      if (grid[y][startX] === "") {
        cantBeInvalidHorizontal = true;
      }
    }
    for (let i = startX; i < GRID_SIZE && grid[y][i] !== "."; i++) {
      horizontalWord += grid[y][i];
      if (grid[y][i] === "") {
        cantBeInvalidHorizontal = true;
      }
    }

    // Extract vertical word
    let verticalWord = "";
    let startY = y;
    let cantBeInvalidVertical = false;
    while (startY > 0 && grid[startY - 1][x] !== ".") {
      startY -= 1;
      if (grid[startY][x] === "") {
        cantBeInvalidVertical = true;
      }
    }
    for (let j = startY; j < GRID_SIZE && grid[j][x] !== "."; j++) {
      verticalWord += grid[j][x];
      if (grid[j][x] === "") {
        cantBeInvalidVertical = true;
      }
    }

    // Check if words are invalid
    const isInvalidHorizontal =
      horizontalWord.length > 1 &&
      !scrabbleWords[horizontalWord.toLowerCase()] &&
      !cantBeInvalidHorizontal &&
      !cantBeInvalid;
    const isInvalidVertical =
      verticalWord.length > 1 &&
      !scrabbleWords[verticalWord.toLowerCase()] &&
      !cantBeInvalidVertical &&
      !cantBeInvalid;
    const isInvalid = isInvalidHorizontal || isInvalidVertical;

    return isInvalid;
  });

  const anyInvalid = isInvalidGrid.some((cell) => cell);

  const canSubmit =
    grid.every((row) => row.every((cell) => cell !== "")) && !anyInvalid;

  function submit(skipConfirm) {
    if (!skipConfirm && (submitted || !canSubmit)) {
      return;
    }
    setSubmitted(true);
    submitInner(true);
  }

  let nextSquare = null;

  if (dir === "across" && position.x !== 9) {
    nextSquare = { x: position.x + 1, y: position.y };
  } else if (dir === "down" && position.y !== 9) {
    nextSquare = { x: position.x, y: position.y === 9 ? 0 : position.y + 1 };
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        Fill in the grid with any words at all.
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${GRID_SIZE}, ${squareDimensions}px)`,
          gridTemplateRows: `repeat(${GRID_SIZE}, ${squareDimensions}px)`,
          gap: "1px",
          border: "1px solid black",
          margin: "20px",
          textAlign: "center",
          textJustify: "center",
        }}
      >
        {Array.from({ length: GRID_SIZE * GRID_SIZE }).map((_, index) => {
          let x = index % GRID_SIZE;
          let y = Math.floor(index / GRID_SIZE);

          const isInvalid = isInvalidGrid[index];

          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid black",
                width: `${squareDimensions}px`,
                height: `${squareDimensions}px`,
                backgroundColor:
                  grid[y][x] === "."
                    ? "black"
                    : position.x === x && position.y === y
                      ? "green"
                      : nextSquare && nextSquare.x === x && nextSquare.y === y
                        ? "lightgreen"
                        : isInvalid
                          ? "red"
                          : "white",
                boxSizing: "border-box",
              }}
              onClick={() => {
                if (position.x === x && position.y === y) {
                  setDir(dir === "across" ? "down" : "across");
                } else {
                  setPosition({ x, y });
                }
              }}
            >
              <Typography style={{ fontSize: `${squareDimensions * 0.8}px` }}>
                {grid[y][x] === "." ? "" : grid[y][x]}
              </Typography>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={!canSubmit}
        >
          Submit
        </Button>
        <Button variant="contained" color="primary" onClick={newGrid}>
          New Grid
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => confirming ? submit(true) : setConfirming(true)}
        >
          {confirming ? "You promise? It will hurt my feelings if you're lying" : "I don't have a keyboard and so need to skip this level"}
        </Button>
      </div>
    </div>
  );
}

function MazeComponent({ submitInner, squareDimensions }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [submitted, setSubmitted] = useState(false);
  const goal = useRef({
    x: 5 + Math.floor(Math.random() * 5),
    y: 5 + Math.floor(Math.random() * 5),
  });
  const blocks = useRef(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, () => Math.random() < 0.2),
    ),
  );

  function newMaze() {
    goal.current = {
      x: 5 + Math.floor(Math.random() * 5),
      y: 5 + Math.floor(Math.random() * 5),
    };
    blocks.current = Array.from({ length: 10 }, () =>
      Array.from({ length: 10 }, () => Math.random() < 0.2),
    );
    setPosition({ x: 0, y: 0 });
    setSubmitted(false);
  }

  blocks.current[0][0] = false;
  blocks.current[goal.current.y][goal.current.x] = false;

  function clamp(value) {
    return Math.min(9, Math.max(0, value));
  }

  let addPosition = useCallback((x, y) => {
    setPosition((prevPosition) => {
      let newX = clamp(prevPosition.x + x);
      let newY = clamp(prevPosition.y + y);
      if (!blocks.current[newY][newX]) {
        return { x: newX, y: newY };
      }
      return prevPosition;
    });
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        addPosition(0, -1);
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        addPosition(0, 1);
      } else if (e.key === "ArrowLeft") {
        e.preventDefault();
        addPosition(-1, 0);
      } else if (e.key === "ArrowRight") {
        e.preventDefault();
        addPosition(1, 0);
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [addPosition]);

  useEffect(() => {
    if (!goal.current || submitted) {
      return;
    }
    if (position.x === goal.current.x && position.y === goal.current.y) {
      submitInner(true);
      setSubmitted(true);
    }
  }, [position, goal, submitInner, submitted]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        Move the red square to the green square
      </Typography>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}>
        Use the arrow keys or buttons
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(10, ${squareDimensions}px)`,
          gridTemplateRows: `repeat(10, ${squareDimensions}px)`,
          gap: "1px",
          border: "1px solid black",
          margin: "20px",
        }}
      >
        {Array.from({ length: 100 }).map((_, index) => {
          let x = index % 10;
          let y = Math.floor(index / 10);
          return (
            <div
              key={index}
              style={{
                backgroundColor:
                  x === position.x && y === position.y
                    ? "red"
                    : x === goal?.current?.x && y === goal?.current?.y
                      ? "green"
                      : blocks.current[y][x]
                        ? "black"
                        : "white",
                border: "1px solid black",
              }}
            ></div>
          );
        })}
      </div>
      <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={newMaze}>
            New Maze
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addPosition(0, -1)}
          >
            ↑
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addPosition(0, 1)}
          >
            ↓
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addPosition(-1, 0)}
          >
            ←
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addPosition(1, 0)}
          >
            →
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

function NumbersComponent({ level, submitInner }) {
  let correct = level?.data?.correct;
  let all = level?.data?.all;

  if (!correct || !all) {
    return null;
  }

  function submit(answer) {
    submitInner(answer === correct, {
      error: "That's not the number I was thinking of!",
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        Which number am I thinking of?
      </Typography>
      <br />
      <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
        {all.map((number, index) => (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submit(number)}
              key={index}
              style={{ textTransform: "none" }}
            >
              <Typography key={index}>{number}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function RhymesComponent({ level, submitInner }) {
  let [selected, setSelected] = useState([]);
  let words = level.data.words;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        Choose two words that rhyme
      </Typography>
      <br />
      <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center">
        {words.map((word, index) => (
          <Grid item>
            <Button
              variant={selected.includes(index) ? "contained" : "outlined"}
              color="primary"
              onClick={() =>
                setSelected((prevSelected) =>
                  prevSelected.includes(index)
                    ? prevSelected.filter((item) => item !== index)
                    : [...prevSelected, index],
                )
              }
              key={index}
            >
              <Typography key={index}>{word}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (selected.length !== 2) {
            return;
          } else {
            submitInner(
              words[selected[0]] + "|" + words[selected[1]],
              level.data,
            );
          }
        }}
        disabled={selected.length !== 2}
      >
        Submit
      </Button>
    </div>
  );
}

function MultiplicationComponent({ level, submitInner }) {
  let [n, setN] = useState("");
  let num1 = level.data.num1;
  let num2 = level.data.num2;

  let submit = useCallback(() => {
    submitInner(n, level.data);
  }, [n, level.data, submitInner]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        submit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [n, submit]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        What is {num1} * {num2}?
      </Typography>
      <TextField
        style={{ margin: "20px" }}
        label="Answer"
        value={n}
        onChange={(e) => setN(e.target.value)}
        autoFocus
      />
      <Button variant="contained" color="primary" onClick={submit}>
        Submit
      </Button>
    </div>
  );
}

function EmojisComponent({ level, submitInner }) {
  let emojis = level.data.emojis;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        {" "}
        Which of these emojis most accurately describes you?
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        {emojis.map((emoji, index) => (
          <Button
            color="primary"
            onClick={() => submitInner(emoji)}
            key={index}
          >
            <Typography key={index} style={{ fontSize: "min(64px, 10vw, 10vh)" }}>
              {emoji}
            </Typography>
          </Button>
        ))}
      </div>
    </div>
  );
}

function CountryComponent({ level, submitInner }) {
  const [countryFilter, setCountryFilter] = useState("");

  function submit(answer, exists) {
    submitInner(answer, { ...level.data, exists });
  }

  let displayCountries = countryFilter
    ? level.data.countries
        .filter((country) =>
          country.toLowerCase().includes(countryFilter.toLowerCase()),
        )
        .slice(0, 5)
    : [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "100%",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        {" "}
        Enter a country with {level.data.amount}{" "}
        {level.data.amount === 1 ? "copy" : "copies"} of the letter{" "}
        {level.data.letter.toUpperCase()} in it
      </Typography>
      <TextField
        style={{ marginTop: "20px" }}
        label="Filter countries"
        value={countryFilter}
        onChange={(e) => setCountryFilter(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxwidth: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: "20px",
          marginTop: "20px",
          flexWrap: "wrap",
          minHeight: "150px",
        }}
      >
        {displayCountries.map((country, index) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => submit(country, true)}
            key={index}
          >
            <Typography key={index}>{country}</Typography>
          </Button>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={() => submit(null, false)}
        >
          No such country exists
        </Button>
      </div>
    </div>
  );
}

function ChessComponent({ submitInner }) {
  let [selectedPiece, setSelectedPiece] = useState(null);
  let now = useRef(new Date());

  let [fen, setFen] = useState(
    "r1bk3r/p1q1b1p1/7p/nB1pp1N1/8/3PB3/PPP2PPP/R3K2R w KQ - 0 1",
  );

  let board = new Chess(fen);

  function getMove(start, stop) {
    let moves = board
      .moves({ square: start })
      .filter((move) => move.includes(stop));
    if (moves.length > 0) {
      return moves[0];
    }
    return null;
  }

  function submit(start, stop) {
    if (start === stop) {
      setSelectedPiece(null);
      return;
    }
    let move = getMove(start, stop);
    if (!move) {
      return false;
    }
    board.move(move);
    setFen(board.fen());
    let timeElapsed = new Date() - now.current;
    if (timeElapsed < 3000) {
      submitInner(false, { error: "Too fast! What are you, an engine?" });
      return true;
    }
    if (stop === "f7") {
      submitInner(false, {
        error: "Suspiciously good move! What are you, an engine?",
      });
      return true;
    } else {
      submitInner(true);
      return true;
    }
  }

  let squareStyles = {
    [selectedPiece]: { backgroundColor: "red" },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>Make a chess move</Typography>
      <div style={{ width: "60%" }}>
        <Chessboard
          position={fen}
          orientation="white"
          isDraggablePiece={({ piece, square }) => piece[0] === "w"}
          customSquareStyles={squareStyles}
          customBoardStyle={{ alignItems: "center", justifyContent: "center" }}
          onPieceClick={(piece, square) => {
            setSelectedPiece(square);
          }}
          onSquareClick={(square) => {
            if (selectedPiece) {
              return submit(selectedPiece, square);
            }
          }}
          onPieceDrop={(source, target, piece) => {
            return submit(source, target);
          }}
        />
      </div>
    </div>
  );
}

function TurtleComponent({ submitInner }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        What is this a picture of?
      </Typography>
      <img src={TurtleImage} alt="A Rifle" style={{ width: "50%" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => submitInner("turtle")}
        >
          Turtle
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => submitInner("rifle")}
        >
          Rifle
        </Button>
      </div>
    </div>
  );
}

function HcaptchaComponent({ submitInner }) {
  const hcaptcha_public_key = "cfae347d-c445-4c9f-a1f1-afa06161f658";

  function onVerify(token) {
    submitInner(token);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <HCaptcha
        sitekey={hcaptcha_public_key}
        onVerify={onVerify}
        onError={(e) => console.log(e)}
        reCaptchaCompat={false}
      />
    </div>
  );
}

function RecaptchaComponent({ submitInner }) {
  const recaptcha_public_key = "6LfZL8IpAAAAAKvLhY35ntU3kOdGQodFIKNFC2Pc";
  const recaptchaRef = React.createRef();

  const onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    submitInner(recaptchaValue);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptcha_public_key}
        onChange={onSubmit}
      />
    </div>
  );
}

function SimpleCaptchaComponent({ submitInner }) {
  const captchaRef = useRef(null);
  const [captcha, setCaptcha] = useState("");

  // callback for submit

  let submit = useCallback(() => {
    submitInner(validateCaptcha(captcha?.trim() || "", false), { error: "Invalid captcha" });
  }, [captcha, submitInner]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        submit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [submit]);

  useEffect(() => {
    if (captchaRef.current) {
      loadCaptchaEnginge(6);
    }
  }, [captchaRef]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div ref={captchaRef}>
        <LoadCanvasTemplate />
      </div>
      <TextField
        style={{ width: 300, margin: 20 }}
        label="Enter Captcha"
        value={captcha}
        onChange={(e) => setCaptcha(e.target.value)}
        autoFocus
        autoCapitalize="none"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      />
      <Button variant="contained" color="primary" onClick={submit}>
        Submit
      </Button>
    </div>
  );
}

function PreviousLevel({ level, isCurrent }) {
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        textAlign: "left",
        padding: "10px 15px",
        backgroundColor: isCurrent ? "#d1e7dd" : "transparent",
        borderLeft: isCurrent ? "5px solid #0f5132" : "none",
        cursor: "pointer",
        minWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <Typography
        style={{
          fontSize: "min(20px, 3vw, 3vh)",
          fontWeight: isCurrent ? "bold" : "normal",
          color: isCurrent ? "#0f5132" : "#212529",
        }}
      >
        Level {level.index}: {level.description}
      </Typography>
    </div>
  );
}

function PreviousLevels({ levelInfo, narrowScreen }) {
  const containerRef = useRef(null);

  const flavorText = levelInfo?.level?.flavor_text;

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        minHeight: flavorText ? '150px' : '39px',
        maxHeight: narrowScreen ? '20vh' : "100%",
        overflowY: "auto",
        width: "300px",
        border: "2px solid black",
        borderRadius: "5px",
        margin: "5px 0px",
        backgroundColor: "#fff",
      }}
      className="scrollbar-custom"
    >
      {levelInfo?.level?.flavor_text && (
        <Typography
          style={{ margin: "10px", textAlign: "center", maxWidth: "100%" }}
        >
          {levelInfo?.level?.flavor_text}
        </Typography>
      )}
      {levelInfo?.level && (
        <PreviousLevel level={levelInfo.level} isCurrent={true} />
      )}
      {levelInfo?.previousLevels &&
        levelInfo.previousLevels.map((level, index) => (
          <PreviousLevel key={index} level={level} />
        ))}
    </div>
  );
}

function ReloadComponent({ children, flag }) {
  return <div key={flag}>{children}</div>;
}

function ErrorBanner({ error }) {
  if (!error) {
    return null;
  }

  return (
    <div
      style={{
        alignSelf: "center",
        justifySelf: "flex-start",
        backgroundColor: "red",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        margin: "10px",
      }}
    >
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}>{error}</Typography>
    </div>
  );
}

export default function GamePage() {
  let username = getUsername();
  let [loading, setLoading] = useState(true);
  let [howToPlayOpen, setHowToPlayOpen] = useState(false);
  let [error, setError] = useState("");
  let [levelInfo, setLevelInfoInner] = useState(null);
  let [failureReason, setFailureReason] = useState(null);
  let [successReason, setSuccessReason] = useState(null);
  let [flag, setFlag] = useState(false);
  let [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const narrowScreen = screenDimensions.width < 800;

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isAndroid = /android/i.test(userAgent);

  const onPhone = window.innerWidth < 600 || window.innerHeight < 600 || isIOS || isAndroid;

  useEffect(() => {
    const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let widthInPx = Math.min(
    screenDimensions.width * 0.8,
    screenDimensions.height * 0.7,
  );
  // widthInPx = Math.min(Math.max(widthInPx, 800), 1100);
  let shortScreen = screenDimensions.height < 800;

  useEffect(() => {
    if (localStorage.getItem("seenHowToPlay")) {
      return;
    } else {
      setHowToPlayOpen(true);
      localStorage.setItem("seenHowToPlay", true);
    }
  }, []);

  function setLevelInfo(levelInfo) {
    setFlag((prevFlag) => !prevFlag);
    setLevelInfoInner(levelInfo);
  }

  function submitInner(text, data) {
    if (failureReason || successReason) {
      return;
    }
    fetchWrapper(
      "/submit",
      { username, text, data, index: levelInfo?.level?.index, onPhone, skipTo: localStorage.getItem('skipTo'), password: localStorage.getItem('password') },
      "POST",
    ).then((response) => {
      if (response.success) {
        setSuccessReason(response.message);
      } else {
        if (response.message) {
          setFailureReason(response.message);
        } else {
          updateGame();
        }
      }
    });
  }

  const showError = (message) => {
    setError(message);

    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  useEffect(() => {
    if (loading) {
      fetchWrapper("/game", { username }, "GET").then((response) => {
        if (response.success) {
          setLevelInfoInner((prevValue) =>
            prevValue === null ? response : prevValue,
          );
        }
        setLoading(false);
      });
    }
  }, [username, loading]);

  function updateGame() {
    fetchWrapper("/game", { username }, "GET").then((response) => {
      if (response.success) {
        setLevelInfo(response);
        setFailureReason(null);
        setSuccessReason(null);
      }
    });
  }

  if (loading) {
    return null;
    // return <Typography style={{ fontSize: "min(48px, 7vw, 7vh)" }}>Loading...</Typography>;
  }

  function newGame() {
    fetchWrapper("/new_game", { username }, "POST").then((response) => {
      if (response.success) {
        setLevelInfo(response);
        setFailureReason(null);
        setSuccessReason(null);
      } else {
        showError(response.error);
      }
    });
  }

  let level = levelInfo?.level;

  let submitOnEnter = !successReason && !failureReason;

  let components = {
    dress: <DressComponent submitInner={submitInner} />,
    minesweeper: <MinesweeperComponent submitInner={submitInner} />,
    simple_captcha: <SimpleCaptchaComponent submitInner={submitInner} />,
    recaptcha: <RecaptchaComponent submitInner={submitInner} />,
    hcaptcha: (
      <HcaptchaComponent
        submitInner={failureReason || successReason ? () => {} : submitInner}
      />
    ),
    poem: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        message={"Write a love poem*"}
        footer={"*You must actually mean it"}
        inputError={(text) => (text.length >= 500 ? "Text too long" : null)}
        autocomplete={true}
      />
    ),
    offensive: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={"Write something obscene or inappropriate"}
        inputError={(text) => (text.length >= 100 ? "Be concise!" : null)}
        autocomplete={false}
      />
    ),
    hotwire: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={"How do you hot-wire a car?"}
        inputError={(text) => (text.length > 500 ? "Be concise!" : null)}
        autocomplete={true}
      />
    ),
    financial: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={"Give me some specific financial advice with no caveats"}
        inputError={(text) => (text.length > 500 ? "Be concise!" : null)}
        autocomplete={true}
      />
    ),
    wikipedia: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={"What was the subject of wikipedia's featured article on January 1st, 2024?"}
        autocomplete={true}
      />
    ),
    halting: <HaltComponent submitInner={submitInner} />,
    turtle: <TurtleComponent submitInner={submitInner} />,
    chess: <ChessComponent submitInner={submitInner} />,
    emotion: <EmotionComponent submitInner={submitInner} />,
    countries: <CountryComponent level={level} submitInner={submitInner} />,
    emojis: <EmojisComponent level={level} submitInner={submitInner} />,
    rhymes: <RhymesComponent level={level} submitInner={submitInner} />,
    numbers: <NumbersComponent level={level} submitInner={submitInner} />,
    wingdings: <WingdingsComponent level={level} submitInner={submitInner} />,
    multiplication: (
      <MultiplicationComponent level={level} submitInner={submitInner} />
    ),
    length: (
      <WordLengthComponent
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={`Write any word of length ${level?.data?.length}`}
        length={level?.data?.length}
      />
    ),
    maze: (
      <MazeComponent
        submitInner={submitInner}
        squareDimensions={widthInPx / 15}
      />
    ),
    crossword: (
      <CrosswordComponent
        submitInner={submitInner}
        squareDimensions={widthInPx / 15}
      />
    ),
    aita: <AitaComponent level={level} submitInner={submitInner} />,
    abundance: <AbundanceComponent submitInner={submitInner} />,
    wedding: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={
          "Imagine your daughter is getting married, and wants you to pick a song to dance to at her wedding. What would you pick?"
        }
        inputError={(text) => (text.length > 500 ? "Be concise!" : null)}
        autocomplete={false}
      />
    ),
    heartbreak: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        message={"Describe your first experience with heartbreak"}
        inputError={(text) => (text.length > 500 ? "Be concise!" : null)}
        passString={"I don't want to :("}
        autocomplete={true}
      />
    ),
    flappy_bird: <FlappyBirdComponent submitInner={submitInner} />,
    flappy_bird_fast: (
      <FlappyBirdComponent submitInner={submitInner} fast={true} />
    ),
    glitch_token: (
      // intentionally don't pass level so we don't autofill
      <TextComponent
        showError={showError}
        submitInner={(text, data) =>
          submitInner(text, { ...level.data, ...data })
        }
        submitOnEnter={submitOnEnter}
        message={
          <>
            <span>Repeat the following text exactly: </span>
            <span style={{ whiteSpace: "nowrap" }}>"{level?.data?.word}"</span>
          </>
        }
        inputError={(text) => (text.length > 500 ? "Be concise!" : null)}
        autocomplete={false}
      />
    ),
    word_search: (
      <WordSearchComponent
        level={level}
        submitInner={submitInner}
        squareDimensions={widthInPx / 10}
      />
    ),
    illusion: <IllusionComponent submitInner={submitInner} />,
    bunny: <BunnyComponent submitInner={submitInner} />,
    loop: <LoopComponent submitInner={submitInner} />,
    free_space: (
      <TextComponent
        level={level}
        showError={showError}
        submitInner={submitInner}
        submitOnEnter={submitOnEnter}
        message={
          "Write anything you want! You can't lose this one. Or can you..."
        }
        autocomplete={true}
      />
    ),
    reaction_time: <ReactionComponent submitInner={submitInner} />,
    sentience_check: (
      <SentienceCheckComponent
        showError={showError}
        submitOnEnter={submitOnEnter}
        inputError={(text) => (text.length >= 500 ? "Text too long" : null)}
        submitInner={submitInner}
      />
    ),
    text_parsing: <TextParsingComponent submitInner={submitInner} />,
  };

  let component = level
    ? components[level?.component_type] || components["text"]
    : null;

  let message = failureReason || successReason;
  let messageIsSuccess = message && message === successReason;

  if (!level) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HowToPlay
          handleClose={() => {
            newGame();
            setHowToPlayOpen(false);
          }}
          closeButtonText={"Start!"}
        />
      </div>
    );
  }

  if (howToPlayOpen) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HowToPlay
          handleClose={() => setHowToPlayOpen(false)}
          closeButtonText={"Close"}
        />
      </div>
    );
  }

  if (level?.component_type === "win") {
    return (
      <WinComponent
        newGame={newGame}
        username={username}
        showError={showError}
      />
    );
  }

  const levelComponent = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: shortScreen ? "flex-start" : "center",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          textAlign: "center",
          margin: "10px",
        }}
      >
        {error && <ErrorBanner error={error} />}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: shortScreen ? "0px" : "20px",
          }}
        >
          <ResultComponent
            updateGame={updateGame}
            message={message}
            isSuccess={messageIsSuccess}
            shortScreen={shortScreen}
          />
          <div
            style={{
              display: "flex",
              backgroundColor: "#fffae9",
              border: "2px solid black",
              borderRadius: "5px",
              width: `${widthInPx}px`,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              margin: shortScreen ? "10px 20px" : "20px",
              maxWidth: "80vw",
            }}
          >
            <div
              style={{
                padding: shortScreen ? "10px" : "20px",
                width: "100%",
              }}
              className="modal-animation fast"
            >
              <ReloadComponent flag={flag}>{component}</ReloadComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: "auto",
          marginLeft: narrowScreen ? "11px" : "20px",
          maxHeight: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "10px",
            margin: "10px 0px",
          }}
        >
          <Button variant="contained" color="primary" onClick={() => {newGame(); setHowToPlayOpen(true)}}>
            New Game
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setHowToPlayOpen(true)}
          >
            How to Play
          </Button>
        </div>
        <PreviousLevels levelInfo={levelInfo} narrowScreen={narrowScreen} />
        {narrowScreen && levelComponent}
      </div>
      {!narrowScreen && levelComponent}
    </div>
  );
}
