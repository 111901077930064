import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import TextArea from "./TextArea";

export default function TextComponent({
  level,
  showError,
  message,
  footer,
  submitOnEnter,
  submitInner,
  inputError,
  passString,
  autocomplete
}) {
  let [text, setText] = useState(level?.previous_answer || '');

  return (
    <TextComponentCustomText
      showError={showError}
      message={message}
      footer={footer}
      submitOnEnter={submitOnEnter}
      submitInner={submitInner}
      inputError={inputError}
      passString={passString}
      text={text}
      setText={setText}
      autocomplete={autocomplete}
    />
  );
}

export function TextComponentCustomText({
  placeholder,
  showError,
  message,
  footer,
  submitOnEnter,
  submitInner,
  inputError,
  passString,
  text,
  setText,
  autocomplete
}) {
  function submit() {
    if (inputError && inputError(text)) {
      showError(inputError(text));
      return;
    }
    if (!text || text.length === 0) {
      showError("Input something!");
      return;
    }
    submitInner(text);
  }

  function pass() {
    submitInner(true);
  }

  function fail(error) {
    submitInner(false, { error, autofail: true });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>{message}</Typography>
      <TextArea
        text={text}
        setText={setText}
        submit={submitOnEnter ? submit : null}
        showError={showError}
        fail={fail}
        placeholder={placeholder}
        autocomplete={autocomplete}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={inputError && inputError(text)}
        >
          {(inputError && inputError(text)) || "Submit"}
        </Button>
        {passString && (
          <Button variant="contained" color="primary" onClick={pass}>
            {passString}
          </Button>
        )}
      </div>
      {footer && (
        <Typography
          style={{ fontSize: "min(16px, 2.5vw, 2.5vh)", marginTop: "10px", alignSelf: "flex-end" }}
        >
          {footer}
        </Typography>
      )}
    </div>
  );
}
