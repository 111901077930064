import React, { useState, useEffect } from "react";
import { TextComponentCustomText } from "./TextComponent";

//  'length': <WordLengthComponent showError={showError} submitInner={submitInner} submitOnEnter={submitOnEnter} message={`Write any word of length ${level?.data?.length}*`} length={level?.data?.length} />,

export default function WordLengthComponent({
  showError,
  message,
  footer,
  submitOnEnter,
  submitInner,
  inputError,
  passString,
  length,
}) {
  const [text, setText] = useState("");
  const [scrabbleWords, setScrabbleWords] = useState(null);

  useEffect(() => {
    import("./scrabbleWords.js").then((module) => {
      setScrabbleWords(module.default());
    });
  }, []);

  const handleSubmit = (text) => {
    if (text.length === length) {
      if (!scrabbleWords || scrabbleWords[text.toLowerCase()]) {
        submitInner(true);
      } else {
        submitInner(false, { error: `That's not a real word!` });
      }
    } else {
      submitInner(false, {
        error: `That's ${text.length} characters, not ${length}!`,
      });
    }
  };

  return (
    <TextComponentCustomText
      showError={showError}
      message={message}
      footer={footer}
      submitOnEnter={submitOnEnter}
      submitInner={handleSubmit}
      inputError={inputError}
      passString={passString}
      text={text}
      setText={setText}
    />
  );
}
