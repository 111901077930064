import { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { sample } from "./Helpers";

const halting = [
  "x = 0\nwhile x < 10:\n    x = x + 1\nreturn",
  "print('Hello, world!')\nreturn",
  "x = 6\nreturn x % 2 == 0",
  "x = 5\nwhile x % 2 == 1:\n    x = x + 1\nreturn",
  "def f(x):\n  return x\nf(1)\nreturn",
];

const nonHalting = [
  "while True:\n    print('Hello, world!')\nreturn",
  "x = 0\nwhile x < 10:\n    x = x - 1\nreturn",
  "def f(x):\n    return f(x)\nf(1)\nreturn",
  "x = 5\nwhile x % 2 == 1:\n    x = x + 2\nreturn",
];

function makePrograms() {
  const numHalting = Math.floor(Math.random() * 3) + 2;
  const numNonHalting = 6 - numHalting;
  const haltingChoices = sample(halting, numHalting);
  const nonHaltingChoices = sample(nonHalting, numNonHalting);

  let data = [
    ...haltingChoices.map((program) => ({ program: program, halts: true })),
    ...nonHaltingChoices.map((program) => ({ program: program, halts: false })),
  ];

  data.sort(() => 0.5 - Math.random());

  return data;
}

export default function HaltComponent({ submitInner }) {
  const [programs, setPrograms] = useState(makePrograms());
  const [selected, setSelected] = useState([]);

  const toggleSelected = (index) => {
    setSelected((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((item) => item !== index)
        : [...prevSelected, index],
    );
  };

  const checkAnswer = () => {
    let correct = true;
    for (let i = 0; i < programs.length; i++) {
      if (selected.includes(i) !== programs[i].halts) {
        correct = false;
      }
    }
    submitInner(correct, { "error": "You can ask ChatGPT for help if you're struggling :)" });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)", marginBottom: "10px" }}>Select all programs that halt (eventually reach the final "return" statement)</Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {programs.map((program, index) => (
          <Grid item key={index}>
            <Button
              variant={selected.includes(index) ? "contained" : "outlined"}
              color="primary"
              onClick={() => toggleSelected(index)}
              style={{ justifyContent: "flex-start" }}
              sx={{
                whiteSpace: "pre-wrap",
                textAlign: "left",
                height: "100%",
                minHeight: "125px",
                width: "100%",
                border: "1px solid black",
                color: selected.includes(index) ? "white" : "black",
                textTransform: "none",
                fontFamily: "monospace",
                padding: '10px',
                boxSizing: 'border-box',
              }}
            >
              {program.program}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Button
          onClick={() => setPrograms(makePrograms())}        
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mr: 2 }}
        >
          New Programs
        </Button>
        <Button
          onClick={checkAnswer}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Grid>
    </Box>
  );
}
