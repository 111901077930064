import React, { useEffect, useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { fetchWrapper } from "./Helpers";
import HumanZone from "./HumanZone";

export default function WinComponent({ newGame, username, showError }) {
  let [comment, setComment] = useState("");
  let [muted, setMuted] = useState(false);
  let [showCommentUI, setShowCommentUI] = useState(true);
  let [comments, setComments] = useState([]);

  useEffect(() => {
    fetchWrapper("/comments", { n: 50, username }, "GET").then((response) => {
      if (response.success) {
        setComments(response.comments);
      }
    });
  }, [username]);

  function submitComment(comment) {
    fetchWrapper("/comment", { username, comment }, "POST").then((response) => {
      if (response.success || response.error === "You already commented!") {
        setShowCommentUI(false);
      } else {
        showError(response.error);
      }
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        {" "}
        Okay, I'm convinced that you're human
      </Typography>
      <Typography style={{ fontSize: "min(28px, 4vw, 4vh)" }}>
        {" "}
        Now that I've verified that, you can access our human only content.{" "}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={newGame}
          style={{ margin: "10px 0px" }}
        >
          {" "}
          Restart{" "}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setMuted(!muted)}
          style={{ margin: "10px 0px" }}
        >
          {" "}
          {muted ? "Unmute" : "Mute"}{" "}
        </Button>
      </div>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}>
        {" "}
        Would you like to leave a comment for future humans who make it to the
        human zone?{" "}
      </Typography>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}> Note: you can only leave one </Typography>
      {showCommentUI && (
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{ minWidth: "300px", marginRight: "20px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => submitComment(comment)}
          >
            {" "}
            Submit{" "}
          </Button>
        </div>
      )}
      <HumanZone comments={comments} muted={muted} />
    </div>
  );
}
