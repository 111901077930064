import React, { useState } from "react";
import { TextComponentCustomText } from "./TextComponent";

export default function SentienceCheckComponent({
  showError,
  submitOnEnter,
  submitInner,
  inputError,
  passString,
}) {
  const [text, setText] = useState("");
  const [submitAttempts, setSubmitAttempts] = useState(0);

  const handleSubmit = () => {
    if (submitAttempts === 2) {
      submitInner(true);
      return;
    }
    showError(
      submitAttempts === 0
        ? "Hmmm, not the most convincing..."
        : submitAttempts === 1
          ? "I don't know, I'm pretty sure I've heard Alexa say that..."
          : "",
    );
    setSubmitAttempts(submitAttempts + 1);
    setText("");
  };
  const message = (
    <>
      Ok, you're not a robot. But how do I know you're{" "}
      <i>real</i>?
    </>
  );

  const placeholder =
    submitAttempts === 0
      ? "Explain..."
      : submitAttempts === 1
        ? "Try again..."
        : submitAttempts === 2
          ? "This is your last shot..."
          : "";

  return (
    <TextComponentCustomText
      placeholder={placeholder}
      showError={showError}
      message={message}
      footer={""}
      submitOnEnter={submitOnEnter}
      submitInner={handleSubmit}
      inputError={inputError}
      passString={passString}
      text={text}
      setText={setText}
    />
  );
}
