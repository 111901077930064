import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Face.css';
import EyesPair from './EyesPair';


const Face = ({ fastLoad, comment, muted }) => {
    const skinColors = ["#FFDBAC", "#F5CFA0", "#EAC086", "#E0AC69", "#C68642"];
    const skinColor = useRef(
        skinColors[Math.floor(Math.random() * skinColors.length)],
    );
    const [showFace, setShowFace] = useState(false);
    const [showEyes, setShowEyes] = useState(false);
    const [showNose, setShowNose] = useState(false);
    const [showMouth, setShowMouth] = useState(false);
    const [temporaryNarrowEyes, setTemporaryNarrowEyes] = useState(false);

    const [haveSpokenUtterance, setHaveSpokenUtterance] = useState(false);

    const [finalNarrowEyes, setFinalNarrowEyes] = useState(false);

    const [animateNose, setAnimateNose] = useState(false);

    const speechSynth = window.speechSynthesis;

    const haveSpokenUtteranceRef = React.useRef(haveSpokenUtterance);

    const showMouthRef = React.useRef(showMouth);
    const temporaryNarrowEyesRef = React.useRef(temporaryNarrowEyes);
    const finalNarrowEyesRef = React.useRef(finalNarrowEyes);

    useEffect(() => {
        haveSpokenUtteranceRef.current = haveSpokenUtterance;
    }, [haveSpokenUtterance]);

    useEffect(() => {
        showMouthRef.current = showMouth;
    }, [showMouth]);

    useEffect(() => {
        temporaryNarrowEyesRef.current = temporaryNarrowEyes;
    }, [temporaryNarrowEyes]);

    useEffect(() => {
        finalNarrowEyesRef.current = finalNarrowEyes;
    }, [finalNarrowEyes]);

    const speakText = useCallback((text) => {
        if (!speechSynth || speechSynth.speaking) {
            return;
        }
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = speechSynth.getVoices().find(voice => voice.lang === 'en-US');
        utterance.pitch = 1;
        utterance.rate = 1;
        if (!muted) {
            speechSynth.speak(utterance);
        }
        setHaveSpokenUtterance(true);
    }, [speechSynth, muted]);

    useEffect(() => {
        const timeouts = [];
        timeouts.push(setTimeout(() => setShowFace(true), 500));
        timeouts.push(setTimeout(() => setShowEyes(true), fastLoad ? 1000 : 1500));
        timeouts.push(setTimeout(() => setShowNose(true), fastLoad ? 1500 : 8000));
        timeouts.push(setTimeout(() => setAnimateNose(true), fastLoad ? 1600 : 8100));
        timeouts.push(setTimeout(() => setShowMouth(true), fastLoad ? 2000 : 10000));
        timeouts.push(setTimeout(() => {
            if (!temporaryNarrowEyes && !finalNarrowEyes && !muted) {
                speakText(comment);
            }
        }, fastLoad ? 2100 : 10100));

        return () => {
            timeouts.forEach(clearTimeout);
        };
    }, [fastLoad, finalNarrowEyes, temporaryNarrowEyes, comment, speakText, speechSynth, muted]);


    useEffect(() => {
        if (muted) {
            speechSynth.cancel();
        }
    }, [muted, speechSynth]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (comment && !speechSynth.speaking && !haveSpokenUtteranceRef.current && !temporaryNarrowEyesRef.current && !finalNarrowEyesRef.current && showMouthRef.current) {
                speakText(comment);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [comment, speechSynth, speakText]);

    const handleFaceClick = () => {
        if (speechSynth.speaking) {
            if (!speechSynth.paused) {
                speechSynth.cancel(); // Pause the current speech
                setTemporaryNarrowEyes(true);
            } else {
                setTemporaryNarrowEyes(false);
            }
        } else {
            setTemporaryNarrowEyes(true);
            setTimeout(() => {
                setTemporaryNarrowEyes(false);
                if (!finalNarrowEyes) {
                    // speakText("Why did you interrupt me?");
                }
            }, 8000);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setFinalNarrowEyes(true);
        }, 30000);
        setTimeout(() => {
            setFinalNarrowEyes(true);
        }, 35000);
        setTimeout(() => {
            setFinalNarrowEyes(true);
        }, 40000);
    }, []);

    const narrowEyes = temporaryNarrowEyes || finalNarrowEyes;

    return (
        <div className={`face ${showFace ? 'grow' : ''}`} style={{ backgroundColor: skinColor?.current }} onClick={handleFaceClick}>
            <div style={{ width: '100%', height: '100%', top: '90%' }}>
                {showEyes && <EyesPair narrowEyes={narrowEyes} />}
            </div>
            {showNose && <div className={`nose ${animateNose ? 'animate-nose' : ''}`}></div>}
            {showMouth && <div className={`mouth ${showMouth ? (narrowEyes ? 'closed-mouth' : 'animate-mouth') : ''}`}></div>}
            {showMouth && comment && !finalNarrowEyes && !temporaryNarrowEyes && (
                <div className="speech-bubble">
                    {comment}
                </div>
            )}
        </div>
    );
};
export default Face;