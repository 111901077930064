import React, { useState, useEffect } from "react";
import "./Eye.css";

const Eye = ({ irisStyle, eyeColor, rightEye, narrowEyes }) => {
  const [animate, setAnimate] = useState(false);

  // Introduce useEffect to handle the automatic start of the animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 100); // Small delay to ensure the animation starts after mounting
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="eye-container">
      <div
        className={`eye ${animate ? "animate" : ""} ${narrowEyes ? "narrow" : ""}`}
        style={{ marginLeft: "10%" }}
      >
        <div
          className={`iris ${animate ? "animate-iris" : ""}`}
          style={{ ...irisStyle, backgroundColor: eyeColor }}
        >
          <div className={`pupil ${animate ? "animate-pupil" : ""}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Eye;
