import React from "react";
import { Grid, Button, Typography } from "@mui/material";

function Wingding({ wingding, submit }) {
  return (
    <Button
      style={{
        backgroundColor: "transparent",
        border: "2px solid black",
        borderRadius: "5px",
      }}
      variant="contained"
      color="primary"
      onClick={() => submit(wingding)}
    >
      <img
        src={"../assets/wingdings/" + wingding + ".png"}
        alt={wingding}
        style={{ width: "100%", height: "auto", aspectRatio: "1 / 1" }}
      />
    </Button>
  );
}

export default function WingdingsComponent({ level, submitInner }) {
  let wingdings = level?.data?.wingdings;

  if (!wingdings) {
    return null;
  }

  function submit(wingding) {
    submitInner(wingding, { ...level.data });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        One of these symbols has been rated by a survey of genuine humans as the
        best. Which one is it?
      </Typography>
      <Grid container spacing={2} style={{ width: "100%", maxWidth: "600px" }}>
        {wingdings.map((wingding, index) => (
          <Grid item xs={6} style={{ width: "40%" }} key={index}>
            <Wingding wingding={wingding} submit={submit} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
