import React, { useState, useEffect } from "react";
import Happy from "./assets/emotions/happy.jpg";
import Sad from "./assets/emotions/sad.jpg";
import Scared from "./assets/emotions/scared.jpg";
import Shocked from "./assets/emotions/shocked.jpg";
import Nervous from "./assets/emotions/nervous.jpg";
import { sample } from "./Helpers";
import { Button, Typography, Grid } from "@mui/material";

let allTests = [
  { options: ["happy", "sad", "scared"], correct: "happy" },
  { options: ["sad", "nervous", "shocked"], correct: "sad" },
  { options: ["scared", "shocked", "nervous"], correct: "scared" },
  { options: ["nervous", "happy", "sad"], correct: "nervous" },
  { options: ["shocked", "nervous", "happy"], correct: "shocked" },
];

let images = {
  happy: Happy,
  sad: Sad,
  scared: Scared,
  shocked: Shocked,
  nervous: Nervous,
};

function TestComponent({ test, setTests, submitInner }) {
  const checkAnswer = (option) => {
    if (option === test.correct) {
      setTests((prevTests) => prevTests.slice(1));
    } else {
      submitInner(false, { error: "Your pareidolia is malfunctioning" });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>
        What emotion is being expressed in this picture?
      </Typography>
      <img
        src={images[test.correct]}
        alt="Emotion"
        style={{ width: "200px", height: "200px", marginBottom: '10px' }}
      />
      <br />
      <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
        {test.options.map((option) => (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => checkAnswer(option)}
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function Done() {
  return (
    <div>
      <Typography style={{ marginBottom: "10px", fontSize: "min(32px, 5vw, 5vh)" }}>
        Good work! Look how happy you made this guy.
      </Typography>
      <img
        src={Happy}
        alt="Happy"
        style={{ width: "min(200px, 30vw, 30vh)", height: "min(200px, 30vw, 30vh)" }}
      />
    </div>
  );
}

function makeTests() {
  let ret = sample(allTests, 3);
  for (let test of ret) {
    test.options = sample(test.options, 3);
  }
  return ret;
}

export default function EmotionComponent({ submitInner }) {
  const [tests, setTests] = useState(makeTests());
  let [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (tests.length === 0 && !submitted) {
      submitInner(true);
      setSubmitted(true);
    }
  }, [tests, submitInner, submitted]);

  let done = tests?.length === 0;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {done ? (
        <Done />
      ) : (
        <TestComponent
          test={tests[0]}
          setTests={setTests}
          submitInner={submitInner}
        />
      )}
    </div>
  );
}
