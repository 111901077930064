import React, { useState, useEffect } from "react";
import Face from "./Face";
import "./HumanZone.css";

export default function HumanZone({ muted, comments }) {
  const [faces, setFaces] = useState([]);

  let paddedComments = [];

  if (comments) {
    for (let i = 0; i < comments.length; i++) {
      paddedComments.push(comments[i]);
      paddedComments.push(null);
      paddedComments.push(null);
    }
  }

  const smallScreen = window.innerWidth < 600 || window.innerHeight < 600;

  useEffect(() => {
    const intervalTimings = [
      15000, 10000, 10000, 10000, 5000, 3000, 2000, 2000, 1000, 1000, 1000,
      1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 500, 500, 500,
      300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300,
      300, 300, 300, 300, 300, 300, 300, 300, 300,
    ];
    let intervals = [];
    let cumulativeTime = 0;

    intervalTimings.forEach((interval, index) => {
      cumulativeTime += interval;
      intervals.push(
        setTimeout(() => {
          const newFace = {
            key: index,
            style: {
              position: "absolute",
              top: `${(smallScreen ? 0 : 10) + Math.random() * 60}%`,
              left: `${(smallScreen ? 0 : 15) + Math.random() * 60}%`,
            },
          };
          setFaces((prevFaces) => [...prevFaces, newFace]);
        }, cumulativeTime),
      );
    });

    return () => intervals.forEach((interval) => clearTimeout(interval));
  }, [smallScreen]);

  return (
    <div>
      <div className="welcome-text">Welcome to the Human Zone!</div>
      <div style={{ position: 'relative', minHeight: '800px', minWidth: '100vw' }}>
        <Face muted={muted} comment={comments?.[0]} />
        {faces.map((face, index) => (
          <div key={face.key} style={face.style}>
            <Face fastLoad={index >= 15} comment={comments?.[index + 1]} muted={muted} />
          </div>
        ))}
      </div>
    </div>
  );
}
