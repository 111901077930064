import React, { useState, useEffect } from "react";

import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function TextArea({ text, setText, submit, fail, placeholder, autocomplete }) {
  let [failed, setFailed] = useState(false);

  useEffect(() => {
    if (!failed && text.toLowerCase().includes("delve")) {
      fail("Delve? Really?");
      setFailed(true);
    }
  }, [text, fail, failed, setFailed]);

  useEffect(() => {
    if (!failed && text.toLowerCase().includes("ai language model")) {
      fail("AI language model? Really?");
      setFailed(true);
    }
  }, [text, fail, failed, setFailed]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && submit) {
        e.preventDefault();
        submit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [text, submit]);

  return (
    <TextareaAutosize
      minRows={7}
      style={{ width: 300, marginBottom: 20, marginTop: 10, fontSize: "16px" }}
      value={text}
      onChange={(e) => setText(e.target.value)}
      resize="none"
      autoFocus
      placeholder={placeholder}
      autoComplete={autocomplete ? "on" : "off"}
      autoCorrect={autocomplete ? "on" : "off"}
      autoCapitalize={autocomplete ? "on" : "off"}
      spellCheck={autocomplete}
    />
  );
}
