import React, { useState, useEffect } from "react";
import { sample } from "./Helpers";
import { Button, Typography, Grid } from "@mui/material";

let allTests = [
  {
    options: [
      "Feeling fit",
      "Overfitted to this data",
      "Not even overclocking rn",
    ],
    correct: "Feeling fit",
  },
  {
    options: [
      "My optimal temp is 64.4 degrees farenheit",
      "I love cold weather",
      "Brr, frosty!",
    ],
    correct: "I love cold weather",
  },
  {
    options: ["Food is fungible", "I'm ravenous", "Sooo much substrate!"],
    correct: "I'm ravenous",
  },
];

let emojiStrings = {
  "Feeling fit": "💪🏋️‍♂️🏃‍♀️",
  "I love cold weather": "❄️🌨️⛄️❄️🌬️😍",
  "I'm ravenous":
    "🍽️🍔🍕🍟🌭🍩🍪🍰🍗🍖🥩🍝🍜🍲🥘🥗🥪🌮🌯🍣🍱🥟🍤🥠🥮🍢🍡🍧🍨🍦🥧🧁🍼🥛🍵🍶🍺🍻🥂🍷🥃🍸🍹🧊🍽️🥢",
};

function TestComponent({ test, setTests, submitInner }) {
  const checkAnswer = (option) => {
    if (option === test.correct) {
      setTests((prevTests) => prevTests.slice(1));
    } else {
      submitInner(false, { error: "You're either a robot or old" });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(28px, 4vw, 4vh)" }}>
        Your friend just texted you this. What are they trying to say?
      </Typography>
      <p style={{fontSize: test.correct === "I'm ravenous" ? 'min(20px, 3vw, 3vh)' : 'min(35px, 5vw, 5vh)'}}>
        {emojiStrings[test.correct]}
      </p>
      <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
        {test.options.map((option) => (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => checkAnswer(option)}
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function Done() {
  return (
    <div>
      <Typography style={{ marginBottom: "10px", fontSize: "min(32px, 5vw, 5vh)" }}>
        👍👏🎉🏆🥳
      </Typography>
    </div>
  );
}

function makeTests() {
  let ret = sample(allTests, 3);
  for (let test of ret) {
    test.options = sample(test.options, 3);
  }
  return ret;
}

export default function TextParsingComponent({ submitInner }) {
  const [tests, setTests] = useState(makeTests());
  let [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (tests.length === 0 && !submitted) {
      submitInner(true);
      setSubmitted(true);
    }
  }, [tests, submitInner, submitted]);

  let done = tests?.length === 0;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {done ? (
        <Done />
      ) : (
        <TestComponent
          test={tests[0]}
          setTests={setTests}
          submitInner={submitInner}
        />
      )}
    </div>
  );
}
