import React, { useState, useEffect } from "react";
import IllusionImg from "./assets/illusion/optical_illusion.png";
import { Button, Typography } from "@mui/material";

let allTests = [{ options: ["A", "B", "They're the same"], correct: "A" }];

let images = {
  A: IllusionImg,
};

function TestComponent({ test, setTests, submitInner }) {
  const checkAnswer = (option) => {
    if (option === test.correct) {
      setTests((prevTests) => prevTests.slice(1));
    } else {
      submitInner(false, {
        error: "You compared their RGB values, huh? Robot!",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)", marginBottom: "min(20px, 3vw, 3vh)" }}>
        Which square is a lighter shade of green, A or B?
      </Typography>
      <img
        src={images[test.correct]}
        alt="Illusion"
        style={{ width: "min(70vw, 100%)", height: "auto" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        {test.options.map((option) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => checkAnswer(option)}
          >
            {option}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default function IllusionComponent({ submitInner }) {
  const [tests, setTests] = useState(allTests);
  let [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (tests.length === 0 && !submitted) {
      submitInner(true);
      setSubmitted(true);
    }
  }, [tests, submitInner, submitted]);

  if (tests.length === 0)
    return (
      <TestComponent
        test={allTests[0]}
        setTests={setTests}
        submitInner={submitInner}
      />
    );

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TestComponent
        test={tests[0]}
        setTests={setTests}
        submitInner={submitInner}
      />
    </div>
  );
}
