import React, { useState, useEffect } from "react";
import "./MinesweeperComponent.css";
import { Typography, Button, Grid } from "@mui/material";
import MinesweeperFlag from "./assets/minesweeper/minesweeper_flag.png";
import MinesweeperTile from "./assets/minesweeper/minesweeper_tile.png";
import MinesweeperNotFlag from "./assets/minesweeper/minesweeper_not_flag.png";

const PUZZLES = [
  [
    [null, 2, null, null, null],
    [null, null, 3, 2, null],
    [3, null, null, 2, null],
    [null, 2, null, null, 1],
    [null, null, 1, 2, null],
  ],
  [
    [1, null, null, 1, null],
    [null, null, null, null, 1],
    [1, null, 2, null, 1],
    [null, 2, 2, null, 3],
    [null, null, null, null, null],
  ],
  [
    [null, 1, null, 2, null],
    [null, 3, null, null, null],
    [null, 2, null, null, 4],
    [null, null, null, 3, null],
    [null, 1, null, null, null],
  ],
  [
    [null, 3, null, 2, null],
    [null, null, null, null, null],
    [null, null, 2, 1, 1],
    [2, null, null, null, null],
    [null, null, 3, 1, null],
  ],
];

const SOLUTIONS = [
  [
    [false, false, false, true, false],
    [true, true, false, false, true],
    [false, true, false, false, false],
    [false, false, true, false, false],
    [false, false, false, false, true],
  ],
  [
    [false, true, false, false, false],
    [false, false, true, false, false],
    [false, false, false, true, false],
    [true, false, false, false, false],
    [true, false, false, true, true],
  ],
  [
    [true, false, false, false, false],
    [false, false, false, true, true],
    [true, false, true, true, false],
    [false, false, false, false, true],
    [true, false, false, false, false],
  ],
  [
    [true, false, true, false, true],
    [true, false, false, false, false],
    [false, false, false, false, false],
    [false, true, false, true, false],
    [false, true, false, false, false],
  ],
];

const NUMBER_TO_COLOR = {
  1: "number-1",
  2: "number-2",
  3: "number-3",
  4: "number-4",
};

const NUMBER_TO_TEXT = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
};

function seededDecision(i, j) {
  const seed = i * 5 + j;
  const pseudoRandom = Math.sin(seed) * 10000;
  const fractional = pseudoRandom - Math.floor(pseudoRandom);
  const wrongColor = fractional < 0.25;
  return {
    rotation: fractional * 360,
    asText: fractional > 0.5,
    wrongColor: wrongColor,
  };
}

export default function MinesweeperComponent({ submitInner }) {
  const [currentPuzzle, setCurrentPuzzle] = useState([]);
  const [flags, setFlags] = useState({});
  const [puzzleIndex, setPuzzleIndex] = useState(0);

  useEffect(() => {
    const index = Math.floor(Math.random() * PUZZLES.length);
    setCurrentPuzzle(PUZZLES[index]);
    setPuzzleIndex(index);
    setFlags({});
  }, []);

  function handleCellClick(i, j, e) {
    e && e.preventDefault();
    if (currentPuzzle[i][j] === null) {
      const key = `${i}-${j}`;
      setFlags((prevFlags) => ({
        ...prevFlags,
        [key]: prevFlags[key] === 0 ? false : prevFlags[key] ? 0 : true,
      }));
    }
  }

  function getCellContent(cell, i, j, displayValue) {
    const key = `${i}-${j}`;
    if (cell === null) {
      return flags[key] ? (
        <img
          src={MinesweeperFlag}
          style={{ width: "min(100px, 15vw, 15vh)", height: "min(100px, 15vw, 15vh)" }}
          alt="Flag"
          draggable="false"
        />
      ) : flags[key] === 0 ? (
        <img
          src={MinesweeperNotFlag}
          style={{ width: "min(100px, 15vw, 15vh)", height: "min(100px, 15vw, 15vh)" }}
          alt="Tile"
          draggable="false"
        />
      ) : (
        <img
          src={MinesweeperTile}
          style={{ width: "min(100px, 15vw, 15vh)", height: "min(100px, 15vw, 15vh)" }}
          alt="Tile"
          draggable="false"
        />
      );
    } else {
      return displayValue;
    }
  }

  function checkSolution() {
    const solution = SOLUTIONS[puzzleIndex];
    for (let i = 0; i < solution.length; i++) {
      for (let j = 0; j < solution[i].length; j++) {
        const key = `${i}-${j}`;
        if (solution[i][j] !== !!flags[key]) {
          submitInner(false);
          return;
        }
      }
    }
    submitInner(true);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>Flag all the mines</Typography>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)" }}>
        Click to toggle between flagged as mine, safe, and unknown
      </Typography>
      <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)", marginBottom: '10px' }}>
        Submit when you've flagged all the squares that are definitely mines or you believe no solution exists
      </Typography>

      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Grid item>
          <div className="grid">
            {currentPuzzle.map((row, i) => (
              <React.Fragment key={i}>
                {row.map((cell, j) => {
                  const { rotation, asText, wrongColor } = seededDecision(i, j);
                  const correctColorClass = NUMBER_TO_COLOR[cell];
                  const wrongColorClass = NUMBER_TO_COLOR[(cell % 4) + 1];
                  const cellClass = `cell ${cell !== null ? (wrongColor ? wrongColorClass : correctColorClass) : ""}`;
                  const displayValue =
                    cell !== null ? (asText ? NUMBER_TO_TEXT[cell] : cell) : "";
                  return cell ? (
                    <div
                      className={cellClass}
                      key={`${i}-${j}`}
                      onClick={() => handleCellClick(i, j)}
                      onContextMenu={(e) => handleCellClick(i, j, e)}
                    >
                      <Typography
                        style={{
                          fontSize: asText ? "min(1.25em, 4.5vw, 4.5vh)" : "min(2.5em, 9vw, 9vh)",
                          fontFamily: "Orbitron",
                          fontWeight: "bold",
                          transform: `rotate(${rotation}deg)`,
                        }}
                      >
                        {getCellContent(cell, i, j, displayValue)}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      onClick={(e) => handleCellClick(i, j, e)}
                      onContextMenu={(e) => handleCellClick(i, j, e)}
                      style={{ cursor: "pointer" }}
                    >
                      {getCellContent(cell, i, j, displayValue)}
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button onClick={checkSolution} variant="contained">
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => submitInner(false, { error: 'Sorry, but a solution did exist!' })} variant="contained">
                No solution exists
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
