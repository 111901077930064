import React, { useState, useEffect, useRef } from "react";
import Eye from "./Eye";

const EyesPair = ({ narrowEyes }) => {
  const eyeColors = [
    "#2e536f",
    "#5a4f7c",
    "#867467",
    "#867467",
    "#867467",
    "#506c64",
    "#a7754d",
    "#a7754d",
  ];
  const eyeColor = useRef(
    eyeColors[Math.floor(Math.random() * eyeColors.length)],
  );
  const [irisStyle, setIrisStyle] = useState({});

  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setAnimate(true);
  };

  const narrowEyesRef = React.useRef(narrowEyes);

  useEffect(() => {
    narrowEyesRef.current = narrowEyes;
  }, [narrowEyes]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (narrowEyesRef.current) return;
      const newTop = Math.random() * 40 - 20;
      const newLeft = Math.random() * 25 + 15;
      setIrisStyle({
        top: `${newTop}%`,
        left: `${newLeft}%`,
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (narrowEyesRef.current) {
      setIrisStyle({
        top: "0%",
        left: "25%",
      });
      return;
    }
  }, [narrowEyes]);

  return (
    <div
      className="eyes-container"
      style={{ display: "flex", justifyContent: "center" }}
      onClick={handleClick}
    >
      <Eye
        irisStyle={irisStyle}
        eyeColor={eyeColor?.current}
        animate={animate}
        narrowEyes={narrowEyes}
      />
      <Eye
        irisStyle={irisStyle}
        eyeColor={eyeColor?.current}
        animate={animate}
        rightEye
        narrowEyes={narrowEyes}
      />
    </div>
  );
};

export default EyesPair;
