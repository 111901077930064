import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";

export default function ResultComponent({
  updateGame,
  message,
  isSuccess,
  shortScreen,
}) {
  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Enter" && message) {
        updateGame();
      }
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [updateGame, message]);

  if (!message) {
    return null;
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <div
        className="modal-animation"
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          border: "none",
          backgroundColor: "#fffae6",
          padding: "20px 20px",
          gap: shortScreen ? "5px" : "10px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      >
        <Typography
          style={{
            fontSize: "min(28px, 4vw, 4vh)",
            color: isSuccess ? "green" : "red",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {isSuccess
            ? "You might be a human. I'm not convinced yet, though"
            : "You are a robot. Get out of here!"}
        </Typography>
        {!isSuccess && (
          <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)", color: "gray" }}>
            You can restart 2 levels back.
          </Typography>
        )}
        {message.toLowerCase() !== "empty" && (
          <Typography style={{ fontSize: "min(24px, 3.5vw, 3.5vh)", fontWeight: "bold" }}>
            {message}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ padding: "10px 20px" }}
          onClick={updateGame}
        >
          {isSuccess ? "Next Level" : "Retry"}
        </Button>
      </div>
    </div>
  );
}
