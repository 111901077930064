import React from "react";
import { Button, Typography } from "@mui/material";

export default function HowToPlayDialog({ handleClose, closeButtonText }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        maxWidth: "80vw",
      }}
      className="modal-animation"
    >
      <Typography style={{ fontSize: "min(32px, 5vw, 5vh)" }}>How To Play</Typography>
      <Typography style={{ maxWidth: "80vw", textAlign: "center" }}>
        The modern internet is full of bots. Your job is to prove you're not one
        of them.
      </Typography>
      <Typography style={{ maxWidth: "80vw", textAlign: "center" }}>
        Once you've done so, we will allow you to access our human-only content.
      </Typography>
      <Typography style={{ maxWidth: "80vw", textAlign: "center" }}>
        You may have to consult external resources for some levels. If you leave and come back, your progress will be saved.
      </Typography>
      <Typography style={{ maxWidth: "80vw", textAlign: "center" }}>
        If you like this game, check out some of our other stuff at{" "}
        <a href="https://glydergames.com" rel="noreferrer" target="_blank">
          glydergames.com
        </a>{" "}
        and{" "}
        <a href="https://drawbackchess.com" rel="noreferrer" target="_blank">
          drawbackchess.com
        </a>.
      </Typography>
      <Button variant="contained" onClick={handleClose}>
        {closeButtonText}
      </Button>
    </div>
  );
}
